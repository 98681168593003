<template>
  <div>
    <section
      class="about-you d-flex align-items-center justify-content-center flex-column position-relative"
    >
      <div
        class="about-you__container text-white w-100 d-flex align-items-center justify-content-lg-start justify-content-center flex-column position-relative mb-3"
      >
        <div
          class="d-flex align-items-center justify-content-sm-center mb-5 flex-column-reverse box-row w-100"
        >
          <div class="mb-3 mb-sm-0 w-100">
            <div
              class="about-you__container__box-graph__graph w-100"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div class="graph-chart w-100">
                <Line ref="lineRef" :data="chartData" :options="options" />
              </div>
              <div class="desc-items d-flex mt-3">
                <div class="mr-3 d-flex align-items-center">
                  <div class="orange"></div>
                  <p>Profissional</p>
                </div>
                <div class="d-flex align-items-center">
                  <div class="blue"></div>
                  <p>Amador</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="about-you__container__description w-100"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <p class="text-start mb-3">
                Ter ao seu lado <b>um profissional nas tomadas de decisões</b> leva você a outro
                patamar!
              </p>
            </div>
          </div>
        </div>

        <div class="about-you__container__description mb-3">
          <p class="text-start mb-3">
            Antes de chegarmos na proposta,
            <b>confira o que os nossos clientes falam da experiência que tiveram conosco</b>:
          </p>
        </div>

        <VideoDepoiments />

        <div class="about-you__container__box-carousel">
          <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="10"
            :autoplay="{
              delay: 3500,
              disableOnInteraction: false
            }"
            :pagination="{ clickable: true }"
            :breakpoints="breakpoints"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="(customer, index) in depositions.customers" :key="index"
              ><div class="about-you__container__box-carousel__carousel bg-white text-black">
                <h5 class="font-rubik-bold font-weight-bold mb-0">{{ customer.name }}</h5>
                <span class="font-rubik">{{ customer.occupation }}</span>
                <div class="d-flex mb-3">
                  <img v-for="i in 5" :key="i" src="@/assets/images/star.svg" alt="estrela" />
                </div>
                <p :class="{ ellipsis: !customer.showFullText }">“{{ customer.deposition }}’’</p>
                <button @click="toggleEllipsis(index)">
                  {{ !customer.showFullText ? 'Ler mais' : 'Ler menos' }}
                </button>
              </div>
              <div class="py-4"></div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="about-you__container__box-line-objectives mt-5">
          <div class="about-you__container__box-line-objectives__items d-flex align-items-center">
            <div class="mb-3 mb-md-0">
              <div
                class="about-you__container__description"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <p class="text-start">
                  Chegou a <b>hora de você também dar esse passo</b> e transformar a sua vida
                  financeira!
                </p>
              </div>
            </div>
            <div>
              <div
                class="about-you__container__box-graph__graph w-100"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <h3 class="font-weight-bold text-center mb-3">Minha linha do tempo</h3>

                <div
                  class="position-relative d-flex align-items-center justify-content-center objective-line"
                >
                  <div class="objective-line__circles">
                    <div
                      class="text-center d-flex align-items-center justify-content-center flex-column"
                      v-for="(objectives, index) in interviewGoals"
                      :key="index"
                    >
                      <div class="orange-circle text-center mb-1">
                        <h3 class="font-weight-bold m-0">{{ index + 1 }}º</h3>
                      </div>
                      <p class="font-weight-bold">{{ objectives.title }}</p>
                      <span :style="objectives.when ? '' : 'width: 95px; height: 18px;'">{{
                        objectives.when
                      }}</span>
                    </div>
                  </div>
                  <img
                    class="w-100 position-absolute img-line"
                    src="@/assets/images/fade-line.svg"
                    alt="line"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { store } from '@/store'
import * as types from '@/store/types'
import { Line } from 'vue-chartjs'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'chart.js/auto'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { storeToRefs } from 'pinia'
import { depositions } from '@/constants/depositions'
import { dateToString } from '@/helpers/converters'
import VideoDepoiments from '@/components/VideoDepoiments.vue'

const { [types.CUSTOMER]: customer } = storeToRefs(store)

const interviewGoals = computed(() => {
  return customer.value?.interview_goals?.map((goal) => {
    if (goal.title === 'Imóvel próprio ou investimento imobiliário') {
      return { ...goal, title: 'Imóvel', when: dateToString(goal.when) }
    } else if (goal.title === 'Segurança financeira da família') {
      return { ...goal, title: 'Segurança', when: dateToString(goal.when) }
    } else if (goal.title === 'Ajudar filhos nos estudos e moradia') {
      return { ...goal, title: 'Ajudar filhos', when: dateToString(goal.when) }
    } else if (goal.title === 'Planejamento organização financeira') {
      return { ...goal, title: 'Planejamento', when: dateToString(goal.when) }
    } else if (goal.title === 'Compra ou troca do veículo') {
      return { ...goal, title: 'Veículo', when: dateToString(goal.when) }
    } else if (goal.title === 'Outros objetivos: casamento, intercâmbio, etc.') {
      return { ...goal, title: 'Outros', when: dateToString(goal.when) }
    }
    return { ...goal, when: dateToString(goal.when) }
  })
})

const white = '#d1d5db'

const labelsRef = computed(() => {
  return customer.value?.chart?.chart_label
})

const proRef = computed(() => {
  return customer.value?.chart?.professional_series
})

const amateurRef = computed(() => {
  return customer.value?.chart?.amateur_series
})

const chartData = computed(() => ({
  labels: labelsRef.value,
  datasets: [
    {
      data: proRef.value,
      borderColor: '#f26237',
      backgroundColor: 'rgba(242, 98, 55, 0.8)',
      pointStyle: 'circle',
      pointRadius: 10,
      pointHoverRadius: 15
    },

    {
      data: amateurRef.value,
      borderColor: '#113183',
      backgroundColor: 'rgba(17, 49, 131, 0.8)',
      pointStyle: 'circle',
      pointRadius: 10,
      pointHoverRadius: 15
    }
  ]
}))

const options = computed(() => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: white,
        weight: 'lighter',
        font: {
          size: 18
        }
      }
    }
  },
  scales: {
    y: {
      ticks: {
        font: {
          size: 14
        },
        stepSize: 25,
        beginAtZero: true,
        color: white
      },
      grid: {
        color: white
      }
    },
    x: {
      ticks: {
        font: {
          size: 14
        },
        stepSize: 1,
        beginAtZero: true,
        color: white
      },
      grid: {
        color: white
      }
    }
  }
}))

const breakpoints = ref({
  1200: {
    slidesPerView: 2
  },
  1670: {
    slidesPerView: 3
  }
})

function toggleEllipsis(index) {
  depositions.users[index].showFullText = !depositions.users[index].showFullText
}

const onSwiper = (swiper) => {}
const onSlideChange = () => {}

const modules = [Navigation, Pagination, Scrollbar, A11y, Autoplay]
</script>

<style lang="scss" scoped>
.about-you {
  width: 100%;
  overflow-x: hidden;

  &__container {
    width: 100%;
    height: 100%;
    padding: 125px 16.666%;

    @include media-sm-down {
      padding: 125px 8.333% !important;
    }

    &__description {
      p {
        font-size: 36px;

        @include media-sm-down {
          font-size: 24px;
        }
      }
    }

    &__box-graph {
      &__graph {
        border-radius: 20px;
        padding: 1.5rem;
        background: linear-gradient(180deg, #303b5280, #303b5200);
        box-shadow: 0px 4px 62px 0px #00000040;

        .graph-chart {
          height: 400px;

          @include media-md-down {
            height: 350px;
            max-width: 350px;
          }
        }

        .desc-items {
          p {
            font-size: 10px;
            font-weight: 400;
          }
          .orange,
          .blue {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 0.5rem;
          }

          .orange {
            background-color: $secondary;
          }

          .blue {
            background-color: #113183;
          }
        }

        .objective-line {
          p {
            font-size: 14px;
          }

          span {
            font-size: 12px;
          }

          &__circles {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 2rem;
          }
        }

        .orange-circle {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          padding: 0.55rem;
          background-color: $secondary;
        }

        .img-line {
          z-index: -1;
          top: 25px;
        }
      }
    }

    &__box-carousel {
      width: 100%;
      &__carousel {
        max-width: 556px;
        // height: 274px;
        padding: 1.5rem;
        border-radius: 20px;

        span {
          font-size: 14px;
        }

        p {
          font-size: 16px;
        }
        button {
          background: none;
          border: none;
          font-size: 16px;
          color: $gray;
          padding: 0;
        }
      }
    }

    &__box-line-objectives {
      &__items {
        @include media-md-down {
          flex-direction: column;
        }
      }
    }
  }
}

.box-row {
  div:nth-child(1) {
    @include media-md-down {
      width: 100%;
    }
  }
  div:nth-child(2) {
    // max-width: 550px;
    @include media-md-down {
      width: 100%;
    }
  }
  @include media-md-down {
    flex-direction: column-reverse;
  }
}

.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
}
</style>
