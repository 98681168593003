<template>
  <section class="position-relative container-section">
    <main class="container-video w-100">
      <div class="box-video">
        <iframe
          width="100%"
          src="https://www.youtube.com/embed/pS-8xNIvwvw?si=DPbuATaEC3EPBQKD"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          aria-label="YouTube video player"
        ></iframe>
      </div>
    </main>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped>
.container-section {
  width: 100%;
  margin-bottom: 2.5rem;
  padding-bottom: 0 !important;
  min-height: auto !important;
}

.container-video {
  padding: 0 15%;
  display: grid;
  place-items: center;

  .box-video {
    width: 100%;
    aspect-ratio: 16 / 9;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 10px solid $tertiary;
    }
  }
}

@include media-lg-down {
  .container-video {
    padding: 0;
  }
}
</style>
